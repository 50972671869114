@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;400;500;700&family=Lato:wght@300;400&display=swap');

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 8px;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@font-face {
  font-family: Circular Std;
  src: url('/fonts/circular-std-medium-500.ttf');
}

@font-face {
  font-family: Circular Std Bold;
  src: url('/fonts/circular-std-bold.ttf');
}

